/*! normalize.css */

@font-face {
    font-family: "FontAwesomeIcon";
    src: url("./fonts/fontawesome-webfont.eot?v=4.7.0");
    src: url("./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("./fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("./fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("./fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("./fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  .wid_320{
    width: 320px !important;
  }
  
  body {
      margin: 0;
  }



  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
      display: block;
  }
  
  *,
  *::before,
  *::after {
      box-sizing: border-box;
  }
  
  * {
      font-family: 'Open Sans', sans-serif, Arial;
  }
  
  html {
      scroll-behavior: smooth;
  }
  
  html,
  body {
      margin: 0px;
      padding: 0px;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 0.025em;
  }
  
  div,
  main,
  section,
  article {
      max-width: 100%;
  }
  
  
  /* Bootstarp CSS */
  .container {
      max-width: none;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  
  @media (min-width: 768px) {
      .container {
          width: 750px;
      }
      .portal-panel {
          padding-right: 0 !important;
      }
  }
  
  @media (min-width: 992px) {
      .container {
          width: 970px;
      }
  }
  
  @media (min-width: 1200px) {
      .container {
          width: 1170px;
      }
  }
  .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  .row {
      max-width: none;
      margin-right: -15px;
      margin-left: -15px;
  }
  .row-no-gutters {
      margin-right: 0;
      margin-left: 0;
  
      [class*="col-"] {
          padding-right: 0;
          padding-left: 0;
      }
  }
  
  /* Mixins starts here */
  @mixin borderRadius($val) {
      border-radius: $val;
      -webkit-border-radius: $val;
      -ms-border-radius: $val;
    }
    @mixin boxShadow($val) {
      box-shadow: $val;
      -webkit-box-shadow: $val;
      -ms-box-shadow: $val;
    }
  
  /* Alerts css starts here */
  .alert {
      padding: 15px;
      margin-bottom: 20px;
      border: 1px solid transparent;
      @include borderRadius(4px);
      h4 {
          margin-top: 0;
          color: inherit;
      }
      p, ul {
          margin-bottom: 0;
      }
      p + p {
          margin-top: 5px;
      }
  }
  .alert-dismissable, .alert-dismissible {
      padding-right: 35px;
  }
  .alert-dismissable, .alert-dismissible {
      .close {
          position: relative;
          top: -2px;
          right: -21px;
          color: inherit;
      }
  }
  .alert-success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
      hr {
          border-top-color: #c9e2b3;
      }
      .alert-link {
          color: #2b542c;
      }
  }
  .alert-info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      hr {
          border-top-color: #a6e1ec;
      }
      .alert-link {
          color: #245269;
      }
  }
  
  .alert-warning {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      hr {
          border-top-color: #f7e1b5;
      }
      .alert-link {
          color: #66512c;
      }
  }
  
  .alert-danger {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      hr {
          border-top-color: #e4b9c0;
      }
      .alert-link {
          color: #843534;
      }
  }
  
  /* Alerts css ends here */
  /*--Mixins Starts Here--*/
  @mixin flex-center() {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .clearfix:before,
  .clearfix:after,
  .container:before,
  .container:after,
  .container-fluid:before,
  .container-fluid:after,
  .row:before,
  .row:after {
      display: table;
      content: " ";
  }
  
  .clearfix:after,
  .container:after,
  .container-fluid:after,
  .row:after {
      clear: both;
  }
  
  .center-block {
      display: block;
      margin-right: auto;
      margin-left: auto;
  }
  
  .pull-right {
      float: right !important;
  }
  
  .pull-left {
      float: left !important;
  }
  .align-center {
      @include flex-center();
  }
  
  /* Custom CSS */
  .m-0,
  .sso-page-alerts p {
      margin: 0;
  }
  
  .mx-0 {
      margin-left: 0;
      margin-right: 0;
  }
  
  .my-0 {
      margin-top: 0;
      margin-bottom: 0;
  }
  
  .p-0 {
      padding: 0;
  }
  
  .px-0 {
      padding-left: 0;
      padding-right: 0;
  }
  
  .py-0 {
      padding-top: 0;
      padding-bottom: 0;
  }
  
  /* Bootstrap CSS ends here */
  
  #root,
  .h-100 {
      height: 100% !important;
  }
  
  .position-relative {
      position: relative !important;
  }
  
  .text-center {
      text-align: center !important;
  }
  main {
      height: 100%;
      background-color: #fff;
      min-height: 100vh;
  }
  .login-container {
      height: 100%;
      margin: 20px 20px 20px 0;
      .container-fluid {
          min-height: calc(100vh - 85px);
      }
  
      div.white-bg {
          min-height: calc(100vh - 150px);
      }
  }
  
  .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  
  .container-fluid {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  
  .white-bg {
      background-color: #ffffff !important;
  }
  
  .sso-page-alerts {
      color: #ae2f12;
      background-color: #ffcdc2;
      ul {
          padding-left: 15px;
          font-size: 13px;
  
          li+li {
              margin-top: 8px;
          }
      }
  }
  
  .banner-panel,
  .portal-panel {
      margin-bottom: 10px;
  }
  
  .sso-page-header {
      @include flex-center();
      padding: 10px;
      background: #fff;
      @include boxShadow(0 0 8px #00000057);
      justify-content: left;
      .portal-logo {
          height: 55px;
      }
      .logo-text {
          font-weight: bold;
          color: #000;
          line-height: 1;
          font-size: 16px;
          display: inline-block;
          padding: 5px 12px;
      }
  }
  
  @media screen and (max-width: 767px) {
      .login-container {
          margin: 20px 15px;
      }
      .portal-panel {
          padding: 0px !important;
      }
      .log-courosal, .banner-panel {
          @include borderRadius(8px !important);
      }
  }
  
  /* courosal Css */
  .log-courosal,
  .banner-panel {
      @include borderRadius(0px 8px 8px 0px);
  }
  
  .log-courosal {
      position: absolute;
      left: 0px;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      overflow: hidden;
  }
  
  .log-banner {
      display: flex;
      height: 100vh;
  
      img {
          width: 100%;
          object-fit: cover;
      }
  }
  
  .carousel-content {
      @include flex-center();
      -webkit-flex-wrap: nowrap;
      -ms-flex-flow: row nowrap;
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      padding: 1em 0.5rem 0 !important;
  
      .carousel-text {
          color: #fff;
          padding: 0 30px;
          background: #3f597403;
          text-align: left;
  
          h1 {
              font-size: 42px;
              font-weight: 300;
              line-height: 1.2;
              margin: 0 0 .5rem;
          }
  
          h4 {
              font-size: 18px;
              font-weight: 400;
              line-height: 1.2;
              margin: 0 0 .5rem;
          }
      }
  }
  
  /* courosal Css End */
  @media screen and (min-width: 768px) and (max-width: 1200px) {
      .container-fluid {
          .portal-wrapper {
              padding: 12px;
          }
          .portal-wrapper-inner {
              margin: 10px;
          }
      }
  }
  
  .side-wrapper {
      padding-right: 0;
  }
  
  .banner-panel {
      position: relative;
      background-color: #274463;
      color: #fff;
      max-height: calc(100% - 150px);
  }
  
  .log-action-center {
      height: calc(100% - 40px);
  
      .log-notifications {
          background-color: #274463;
          padding: 10px;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          margin-bottom: 12px;
          @include borderRadius(8px);
  
          .notify-heading {
              color: #fff;
              padding-bottom: 5px;
              margin: 0 0 0.5em 0;
              font-size: 14px;
              font-weight: 600;
              border-bottom: solid 3px #00B4A0;
  
              .hd-toggle {
                  float: right;
                  padding-top: 5px;
                  font-size: 10px;
                  font-weight: 400;
                  cursor: pointer;
              }
          }
  
          .notify-link {
              font-size: 13px;
              font-weight: 400;
              line-height: 1.3;
              color: #fff;
              padding: 5px 0;
              display: block;
          }
      }
  }
  
  .purple-brd {
      border-color: #b57fec !important;
  }
  
  /* User portal CSS */
  .portal-wrapper {
      height: 100%;
      width: 100%;
      border: solid 1px #dadddc;
      @include borderRadius(8px);
      background-color: #fbfbfb;
      padding: 1.5em;
  }
  .portal-wrapper-inner {
      margin: 20px 10px;
  }
  .welcome-user {
      h1 {
          color: #000;
          font-size: 18px;
          text-align: center;
          font-weight: 600;
      }
      .heading-text {
          font-size: 12px;
          text-align: center;
      }
  }
  .portal-menu-item {
      display: block;
      text-decoration: none;
      color: #000000;
      padding: 10px 20px 10px 70px;
      margin-bottom: 4px;
      border: 1px solid #54595533;
      @include borderRadius(5px);
      position: relative;
      transition: all .15s ease-in-out;
      -webkit-transition: all .15s ease-in-out;
      min-height: 50px;
      cursor: pointer;
      &:hover {
          border-color: #274463;
          @include boxShadow(0 0 6px #00000024);
          text-decoration: none;
          * {
              color: inherit;
          }
      }
  }
  .portal-menu {
      margin-bottom: 25px;
      &.error-state {
          .portal-menu-item {
              border: 1px solid #A81F00;
              cursor: not-allowed;
          }
          .portal-menu-error {
              display: block;
          }
      }
      .portal-menu-icon {
          @include flex-center();
          height: 35px;
          width: 35px;
          position: absolute;
          left: 15px;
          top: calc(50% - 1.25em);
          background: #274463;
          color: #fff;
          @include borderRadius(40px);
          padding: 5px;
          &::before {
              font-family: "FontAwesomeIcon";
              font-size: 16px;
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              -webkit-transform: translate(0, -50%);
          }
      }
      .portal-menu-title {
          font-size: 13px;
          font-weight: 600;
          margin: -1px 0 -2px;
          color: #000;
      }
      .portal-menu-desc {
          color: #545955e5;
          font-size: 10px;
      }
      .portal-menu-error {
          color: #A81F00;
          font-size: 10px;
          display: none;
      }
  }
  .set-logOut {
      min-height: calc(100% - 65px);
  }
  
  .disable-state {
      .portal-menu-item:hover {
          border: 1px solid #54595533 !important;
      }
      .portal-menu-icon {
          background: #CCCCCC;
          color: #fff;
      }
      .portal-menu-title, .portal-menu-desc {
          color: #cccccce5 !important;
      }
  }
  
  .file-text-icon:before {
      content: "\f15c";
  }
  .address-card-icon:before {
    content: "\f2bc";
    }
  .usd-icon:before {
      content: "\f155";
  }
  .group-users-icon:before {
      content: "\f0c0";
  }
  .user-icon::before{
    content: "\f007";
    -webkit-text-fill-color: #274463;
    -webkit-text-stroke-color: #fff;
    -webkit-text-stroke-width: 1px;
  }
  .hostbrowser-portal:before {
      content: "\f233";
  }
  
  .user-logout {
      padding-top: 15px;
      .logOut-btn {
          color: #666;
          display: inline-block;
          cursor: pointer;
          transition: all .15s ease-in-out;
          -webkit-transition: all .15s ease-in-out;
          &:hover {
              color: #274463;
              span {
                  border-color: #274463;
              }
          }
      }
      span {
          padding: 2px 4px;
          border: solid 0.1rem #999;
          @include borderRadius(100%);
          height: 21px;
          width: 21px;
          display: inline-block;
          line-height: 1;
          margin-right: 3px;
          &::before {
              font-family: "FontAwesomeIcon";
              content: "\f08b";
              font-size: 12px;
          }
      }
  }
  .my-account {
    padding: 2px 4px;
    border: solid 0.1rem #999;
    @include borderRadius(100%);
    height: 21px;
    width: 21px;
    display: inline-block;
    line-height: 1;
    margin-right: 3px;
    &::before {
        font-family: "FontAwesomeIcon" !important;              
        content: "\f023" !important;
        font-size: 12px;
    }
}
  
  /* Footer CSS starts here */
  .sso-page-footer {
      margin-top: 10px;
  }
  .footer-logo {
      display: flex;
      margin: 0 15px;
      .poweredBy {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
          padding-top: 14px;
      }
      .bot-logo img {
          width: 160px;
          height: auto;
      }
  }
  
  .footer-nav-items {
      ul {
          display: inline-block;
          margin: 10px 0;
          padding: 0;
          li {
              float: left;
              list-style-type: none;
              a {
                  color: #0047BA;
                  font-size: 9px;
                  text-decoration: none;
                  padding: 0px 13px;
                  border-right: 1px solid #0047BA;
              }
              &:last-child a {
                  border-right: none;
                  padding-right: 0px;
              }
          }
      }
  }

  