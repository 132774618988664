
        @media print {
            body {
              visibility: hidden;
              -webkit-print-color-adjust: exact;
            }

            #section-to-print {
              visibility: visible;
              position: absolute;
              left: 0;
              top: 0;
            }
          }